/* Variables */

html {
	--grey: #e7e7e7;
	--gray: var(--grey);
	--blue: #0072b9;
	--pink: #d60087;
	--yellow: #ffc600;
	--black: #2e2e2e;
	--red: #c73737;
	--green: #61e846;
	--text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
	--box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
	--size: 20px;

	font-size: 62.5%;
	font-family: 'Calistoga', cursive;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	font-size: 2rem;
	line-height: 1.5;
	background-color: #dfdbe5;
	background-image: url(./images/topography.svg);
}

.wrapper {
	max-width: 1000px;
	margin: 4rem auto;
	padding: 2rem;
	background: white;
}

.box,
.wrapper {
	box-shadow: 0 0 3px 5px rgba(0, 0, 0, 0.08653);
}

a {
	color: var(--blue);
	text-decoration-color: var(--yellow);
}

a.button,
button,
input[type='button'] {
	color: white;
	background: var(--pink);
	padding: 1rem;
	border: 0;
	border: 2px solid transparent;
	text-decoration: none;
	font-weight: 600;
	font-size: 2rem;
}

:focus {
	outline-color: var(--pink);
}

fieldset {
	border: 1px solid black;
}

input:not([type='checkbox']):not([type='radio']),
textarea,
select {
	display: block;
	padding: 1rem;
	border: 1px solid var(--grey);
}

.wrapper h1,
.wrapper h2,
.wrapper h3,
.wrapper h4,
.wrapper h5,
.wrapper h6 {
	color: var(--black);
	text-shadow: none;
}

.wrapper {
	text-align: center;
}

.joke {
	font-size: 5rem;
	font-weight: 900;
}

.lds-ripple {
	display: inline-block;
	position: relative;
	width: var(--size);
	height: var(--size);
}

.lds-ripple div {
	position: absolute;
	border: 4px solid white;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}

.hidden {
	display: none;
}

@keyframes lds-ripple {
	0% {
		top: calc(var(--size) / 2);
		left: calc(var(--size) / 2);
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 0px;
		left: 0px;
		width: calc(var(--size) * 0.9);
		height: calc(var(--size) * 0.9);
		opacity: 0;
	}
}
